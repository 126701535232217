import React from "react";

import api from "./api";

// Set available actions to track
interface Context {
  actions: {
    track: {
      createAccount: (slug: string, email: string) => void;
    };
  };
}

// Create the context
const AnalyticsContext = React.createContext({} as Context);

// Create the provider
const AnalyticsProvider: React.FC = ({children}) => {
  function createAccount(slug: string) {
    return api.track({
      value: 1,
      category: "account",
      action: "created",
      label: slug,
    });
  }

  React.useEffect(() => {
    // Track page view
    api.pageview(window.location.pathname + window.location.search);
  }, []);

  // Export the available actions
  const actions: Context["actions"] = {
    track: {
      createAccount,
    },
  };

  return <AnalyticsContext.Provider value={{actions}}>{children}</AnalyticsContext.Provider>;
};

// Export a named provider and the context as default
export {AnalyticsProvider as Provider, AnalyticsContext as default};
